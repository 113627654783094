import React from 'react';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme, BaseStyles } from '@easehr/ease-design-system';

const Base = styled.div``;

const ThemeProvider = ({ ...props }) => (
  <StyledThemeProvider theme={theme}>
    <React.Fragment>
      <BaseStyles suppressMultiMountWarning />
      <Base {...props} />
    </React.Fragment>
  </StyledThemeProvider>
);

export default ThemeProvider;
