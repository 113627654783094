const keyCodes = {
    delete: 8,
    tab: 9,
    enter: 13,
    escape: 27,
    spacebar: 32,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    backspace: 46,
    a: 65,
    pageUp: 33,
    pageDown: 34,
    shift: 16
};

export { keyCodes }; // eslint-disable-line
